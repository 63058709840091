// AssignmentModal.jsx
import React, { useState } from 'react';

const AssignmentModal = ({ vehiclePlans, onClose, onAssign, isOpen }) => {
    const [selectedPlan, setSelectedPlan] = useState("");

    if (!isOpen) return null;

    const handleConfirm = () => {
        onAssign(selectedPlan); // Directly pass the selected plan name
        onClose();
    };

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Select a Route</h2>
                <select value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                    <option value="">--Please choose an option--</option>
                    {vehiclePlans.map((plan, index) => (
                        <option key={index} value={plan.routeName}>
                            {plan.routeName}
                        </option>
                    ))}
                </select>
                <div className="modal-actions">
                    <button onClick={handleConfirm} disabled={!selectedPlan} className="toggle-button relative flex items-center justify-center h-auto w-40 bg-gray-300 hover:bg-green-600 dark:bg-gray-800 text-green-500 hover:text-white hover:rounded-xl rounded transition-all duration-300 ease-linear cursor-pointer shadow-lg">Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default AssignmentModal;
