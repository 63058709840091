import React from 'react';

const StatusH = ({ chartData, setSelectedFile, availableFiles }) => {
  const totalOrders = chartData.reduce((sum, route) => sum + route.orders.length, 0);
  const totalRoutes = chartData.length;
  const unassignedOrders = chartData.length > 0 ? chartData[0].unassignedOrders || 0 : 0;

  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.value + ".json"); // Append ".json" when setting the selected file
    };

  return (
    <div className="bg-gray-50 p-4 rounded-md shadow-md mx-auto max-w-35 flex flex-row justify-center">
      <div className='border-r border-l border-gray-600 pb-1'></div>
      {/* Dropdown for selecting JSON file */}
      <select onChange={handleFileChange} className="your-dropdown-classes">
        {availableFiles.map((file) => {
          const filenameWithoutExtension = file.replace(/\.json$/, '');
          return (
            <option key={file} value={filenameWithoutExtension}>
              {filenameWithoutExtension}
            </option>
          );
        })}
      </select>
      <div className='border-r border-l border-gray-600 pb-1'></div>
      <div className="mx-4">
        <p>Routes Planned: {totalRoutes}</p>
      </div>
      <div className='border-r border-l border-gray-600 pb-1'></div>
      <div className="mx-4">
        <p>Orders: {totalOrders}</p>
      </div>
      <div className='border-r border-l border-gray-600 pb-1'></div>
      <div className="mx-4">
        <p>Unassigned Orders: {unassignedOrders}</p>
      </div>
      <div className='border-r border-l border-gray-600 pb-1'></div>
    </div>
  );
};

export default StatusH;
