import React from 'react';
import { Navbar} from '../components'
import { Topbar } from '../containers'

const Dashboard = () => {
  return (
    <div>
      <Navbar />
      <Topbar />
      <div className='ml-16'>
      <div className="container mx-auto mt-16 p-4"> {/* Add margin-top and padding */}
        <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
        <div className="grid grid-cols-2 gap-4"> {/* Example grid layout with 2 columns */}
          <div className="bg-white p-4 rounded-md shadow-md">
            <h1>Need to decide what metrics to put here</h1>
          </div>
          <div className="bg-white p-4 rounded-md shadow-md">
            {/* Content for the second container */}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;