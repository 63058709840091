import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ onTimerEnd }) => {
  const initialTime = 15 * 60; // 15 minutes in seconds
  const [secondsRemaining, setSecondsRemaining] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsRemaining((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timer);
          if (onTimerEnd) {
            onTimerEnd();
          }
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount

  }, [onTimerEnd]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
      <p>Time remaining: {formatTime(secondsRemaining)}</p>
    </div>
  );
};

export default CountdownTimer;
