import React, { useState, useEffect, useMemo } from 'react';
import GanttControlPanel from './ganttControlPanel';
import GanttChartContent from './ganttChartContent';
import apiFetch from '../../utils/api';

const GanttChart = ({ chartData, refreshChartData }) => {
  const START_HOUR = 0;
  const BASE_END_HOUR = 24;

  // Predefined zoom levels and their corresponding intervals in minutes
  const ZOOM_LEVELS = [60, 30, 15, 1];
  const [currentZoomLevel, setCurrentZoomLevel] = useState(0); // Index of the ZOOM_LEVELS array

  // State for operational hours
  const operationalHours = useMemo(() => {
    const interval = ZOOM_LEVELS[currentZoomLevel];
    const totalMinutes = (BASE_END_HOUR - START_HOUR) * 60;
    const numberOfIntervals = Math.ceil(totalMinutes / interval);
    return numberOfIntervals;
  }, [currentZoomLevel, ZOOM_LEVELS]);

  const [ordersStatus, setOrdersStatus] = useState({});
  const [errorShown, setErrorShown] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [fetchError, setFetchError] = useState(false); // Add error state

  useEffect(() => {
    const fetchOrderStatus = async () => {
      setLoading(true); // Start loading
      setFetchError(false); // Reset error state
      try {
        const data = await apiFetch('/api/deliverystatus-uat');
        const statusMap = {};
        data.forEach((item) => {
          statusMap[item.customerOrderHeaderId] = {
            isCompleted: item.isCompleted,
            lastUpdate: new Date(item.lastUpdate),
          };
        });
        setOrdersStatus(statusMap);
      } catch (error) {
        console.error('Error fetching order status:', error);
        setFetchError(true); // Set error state
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchOrderStatus();
  }, [errorShown]);

  return (
    <div className="flex flex-col w-full">
      <GanttControlPanel
        currentZoomLevel={currentZoomLevel}
        setTargetZoomLevel={(zoomLevel) => {
          setCurrentZoomLevel(zoomLevel);
        }}
        zoomLevels={ZOOM_LEVELS}
      />
      {loading ? (
        <div className="flex justify-center items-center h-center">
          <div className="relative w-12 h-12 mb-4">
            <div className="w-full h-full rounded-full absolute border-4 border-solid border-gray-200"></div>
            <div className={`w-full h-full rounded-full absolute border-4 border-solid ${fetchError ? 'border-red-500' : 'border-green-500'} border-t-transparent ${fetchError ? '' : 'animate-spin'}`}></div>
          </div>
          <div className="loading-message text-gray-800">{fetchError ? 'No data available' : 'Loading...'}</div>
        </div>
      ) : (
        chartData.length > 0 ? (
          <GanttChartContent
            chartData={chartData}
            operationalHours={operationalHours}
            currentZoomLevel={currentZoomLevel}
            ordersStatus={ordersStatus}
            startHour={START_HOUR}
            zoomLevels={ZOOM_LEVELS} // Pass ZOOM_LEVELS as a prop
            refreshChartData={refreshChartData}
          />
        ) : (
          <div className="flex justify-center items-center h-center">
            <div className="loading-message text-gray-800">No data available</div>
          </div>
        )
      )}
    </div>
  );
};

export default GanttChart;
