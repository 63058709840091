import React, { useState, useMemo } from 'react';
import { BsLightningCharge, BsCheckLg } from "react-icons/bs";
import { usePopup } from '../../components/loading/Popup';
import AssignmentModal from './AssignmentModal';
import apiFetch from '../../utils/api';

const UnassignedOrders = ({ unplannedJobs, chartData, onClose, refreshChartData }) => {
  const { showPopup } = usePopup();
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);
  const [selectedPlans, setSelectedPlans] = useState({});

  const unassignedOrders = useMemo(() => {
    return unplannedJobs.map(job => {
      const deliveryStops = job.stops.filter(stop => stop.type === 'SHIPMENT_DELIVERY');
      return deliveryStops.map(stop => ({
        id: job._id,
        orderName: stop.properties.Client,
        weight: parseFloat(stop.properties.Weight).toFixed(2),
        sale: parseFloat(stop.properties.GrossSale).toFixed(2),
        eircode: stop.properties.Eircode,
        requiredSkills: job.requiredSkills.join(', '),
        deliveryWindow: `${new Date(stop.openTime).toLocaleTimeString()} - ${new Date(stop.closeTime).toLocaleTimeString()}`,
      }));
    }).flat();
  }, [unplannedJobs]);

  const handleAssignClick = (job) => {
    setCurrentJob(job);
    setIsAssignmentModalOpen(true);
  };

  const handlePlanAssign = (planName) => {
    setSelectedPlans(prevPlans => ({
      ...prevPlans,
      [currentJob.id]: planName
    }));
    setIsAssignmentModalOpen(false);
    showPopup("Plan assigned", "info");
  };

  const confirmPlanAssign = async () => {
    showPopup("Making Changes and Optimizing", "info");

    try {
      const jobsPayload = {
        jobs: unplannedJobs.map(job => ({
          ...job,
          requiredSkills: selectedPlans[job._id] ? [selectedPlans[job._id]] : job.requiredSkills
        }))
      };

      await apiFetch('/api/optimizeAll', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jobsPayload),
      });

      showPopup("Optimization successful", "positive");
      refreshChartData();
      onClose();  // Close the modal or update the UI as needed
    } catch (error) {
      console.error('Optimization failed:', error);
      showPopup("Error optimizing Unassigned orders", "negative");
    }
  };

  const handleOptimize = async () => {
    showPopup("Auto Optimizing.", "info");

    try {
      const jobsPayload = {
        jobs: unplannedJobs.map(job => ({
          ...job,
          requiredSkills: ["-"]  // Adjust the requiredSkills as specified
        }))
      };

      await apiFetch('/api/optimizeAll', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jobsPayload),
      });

      setTimeout(() => {
        refreshChartData(); // Refresh the chart data after a delay
      }, 3000); // Adjust the delay as necessary
      showPopup("Optimization successful", "positive");
      onClose();  // Close the modal or update the UI as needed
    } catch (error) {
      console.error('Optimization failed:', error);
      showPopup("Error optimizing Unassigned orders", "negative");
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="relative bg-white p-4 rounded-lg shadow-md min-w-[300px] max-w-[90%] mx-auto">
        <button onClick={onClose} className="absolute top-0 right-0 m-2 text-lg font-semibold rounded-full text-gray-600 hover:text-gray-800">
          &times;
        </button>
        <h2 className="text-lg mb-4 text-center">Unassigned Orders</h2>
        <div className="overflow-y-auto max-h-[500px]">
          <table className="table-auto w-full">
            <thead className='sticky top-0 bg-white'>
              <tr className="bg-gray-200 text-left">
                <th>Order</th>
                <th className='p-2'>Customer</th>
                <th className='p-2'>Post Code</th>
                <th className='p-2'>Weight</th>
                <th className='p-2'>Delivery Window</th>
                <th className='p-2'>Sale</th>
                <th className='p-2'>Original Route</th>
                <th className='p-2 text-right'>Assign</th>
              </tr>
            </thead>
            <tbody>
              {unassignedOrders.map((order, index) => (
                <tr key={`${order.id}-${index}`} className="border-b">
                  <td>{order.id}</td>
                  <td className="p-2">{order.orderName}</td>
                  <td className="p-2">{order.eircode}</td>
                  <td className="p-2">{order.weight}Kg</td>
                  <td className="p-2 text-center">{order.deliveryWindow}</td>
                  <td className="p-2">€{order.sale}</td>
                  <td className='p-2'>{order.requiredSkills}</td>
                  <td>
                    <button onClick={() => handleAssignClick(order)} className="text-green-500 hover:text-green-700 p-2 text-right">
                      {selectedPlans[order.id] ? `${selectedPlans[order.id]}` : "Assign"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-center w-full">
          <button onClick={handleOptimize} className="toggle-button relative flex items-center justify-start h-auto w-auto p-1 ml-2 bg-gray-400 hover:bg-green-600 dark:bg-gray-800 text-green-500 hover:text-white hover:rounded-xl rounded transition-all duration-300 ease-linear cursor-pointer shadow-lg">
            <BsLightningCharge size={22} />
            <span className='ml-2'>Auto Optimize</span>
          </button>
          <button onClick={confirmPlanAssign} className="toggle-button relative flex items-center justify-start h-auto w-auto p-1 ml-4 bg-gray-400 hover:bg-green-600 dark:bg-gray-800 text-green-500 hover:text-white hover:rounded-xl rounded transition-all duration-300 ease-linear cursor-pointer shadow-lg">
            <BsCheckLg size={22} />
            <span className='ml-2'>Confirm Changes</span>
          </button>
        </div>
        {isAssignmentModalOpen && (
          <AssignmentModal 
            isOpen={isAssignmentModalOpen}
            onClose={() => setIsAssignmentModalOpen(false)}
            vehiclePlans={chartData}
            onAssign={handlePlanAssign}
          />
        )}
      </div>
    </div>
  );
};

export default UnassignedOrders;
