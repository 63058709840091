// src/components/SideBar.jsx
import React, { useRef } from 'react';
import { BsPlus, BsGearFill } from 'react-icons/bs';
import { FaChartBar, FaHistory } from 'react-icons/fa';
import { LuLayoutDashboard } from 'react-icons/lu';
import { MdOutlineDataSaverOn } from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { FaRoute } from 'react-icons/fa6';
import { NavLink, useNavigate } from 'react-router-dom';
import { Import } from '../../containers';
import { usePopup } from '../loading/Popup';
import { useAuth } from '../../context/AuthContext';
import apiFetch from '../../utils/api';

const SideBar = () => {
  const fileInputRef = useRef(null);
  const { showPopup } = usePopup();
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const saveplan = async () => {
    showPopup("Plan is updating and saving, reports being generated.", "info");
    try {
        const data = await apiFetch('/api/saveplan', {
            method: 'GET', 
        });
        showPopup("Plan updated and reports generated successfully.", "positive");
    } catch (error) {
        showPopup(`Unable to save plan: ${error.message}`, "negative");
    }
};

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleUploadSuccess = (data) => {
    console.log('Upload successful', data);
  };

  const handleUploadError = (error) => {
    console.error('Upload error', error);
  };

  const handleLogout = () => {
    logout(); 
};


return (
  <div className="fixed top-0 left-0 h-screen w-16 flex flex-col bg-white dark:bg-gray-900 shadow-lg z-50">
    <NavLink to='/'>
      <SideBarIcon icon={<FaRoute size="28" />} text='Live Routes' />
    </NavLink>
    <Divider />
    {user?.user_type === 'admin' && (
      <>
        <button onClick={handleFileUpload}>
          <SideBarIcon icon={<BsPlus size="32" />} text='Import Orders' />
        </button>
        <Import onUploadSuccess={handleUploadSuccess} onUploadError={handleUploadError} fileInputRef={fileInputRef} />
      </>
    )}
    <NavLink to='/historical'>
      <SideBarIcon icon={<FaHistory size="20" />} text='Historical Routes' />
    </NavLink>
    <NavLink to='/reports'>
      <SideBarIcon icon={<FaChartBar size="20" />} text='Reports' />
    </NavLink>
    <NavLink to='/dashboard'>
      <SideBarIcon icon={<LuLayoutDashboard size="20" />} text='Dashboard' />
    </NavLink>
    <div className="mt-auto pb-5">
      {user?.user_type === 'admin' && (
        <button className='ml-2' onClick={saveplan}>
          <SideBarIcon icon={<MdOutlineDataSaverOn size="28" />} text='Save Plan' />
        </button>
      )}
      <NavLink to='/settings'>
        <Divider />
        <SideBarIcon icon={<BsGearFill size="22" />} text='Settings' />
      </NavLink>
      <button className='ml-2' onClick={handleLogout}>
        <SideBarIcon icon={<BiLogOutCircle size="28" />} text='Logout' />
      </button>
    </div>
  </div>
);
};

const SideBarIcon = ({ icon, text = 'tooltip 💡' }) => (
  <div className="sidebar-icon group">
    {icon}
    <span className="sidebar-tooltip group-hover:scale-100">
      {text}
    </span>
  </div>
);

const Divider = () => <hr className="sidebar-hr" />;

export default SideBar;
