// TopBar.jsx
import React from 'react';
import { useAuth } from '../../context/AuthContext';

const TopBar = () => {
  const { user } = useAuth();

  return (
    <header className="bg-white dark:bg-gray-900 shadow-lg p-4 fixed top-0 w-full z-40">
      <div className="container mx-auto flex justify-center items-center">
        <div className="flex items-center mx-auto">
          <img src='/rmlogo.jpg' alt="Logo" className="h-8 mr-0" />
          <span className="text-lg font-bold text-gray-800">Route Metrics</span>
        </div>
        <div></div>
        {user && (
            <div className="text-sm semi-bold text-gray-800">
               {user.username}
            </div>
        )}
      </div>
    </header>
  );
};

export default TopBar;