import React, { createContext, useContext, useState, useEffect } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';

const PopupContext = createContext();

export const usePopup = () => useContext(PopupContext);

export const PopupProvider = ({ children }) => {
  const [popup, setPopup] = useState({ show: false, message: "", type: 'info' });

  useEffect(() => {
    let timer;
    if (popup.show) {
      // Automatically hide the popup after 3 seconds
      timer = setTimeout(() => {
        setPopup(currentPopup => ({ ...currentPopup, show: false }));
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [popup.show]); // Ensured popup.show is the only dependency

  const showPopup = (message, type = 'info') => {
    setPopup({ show: true, message, type });
  };

  const hidePopup = () => setPopup({ ...popup, show: false });

  return (
    <PopupContext.Provider value={{ showPopup, hidePopup }}>
      {children}
      <div className={`fixed inset-x-0 bottom-0 mb-4 flex justify-center z-50 transition-all ease-out duration-300 ${popup.show ? "translate-y-0 opacity-100" : "-translate-y-10 opacity-0 pointer-events-none"}`}>
        <div className={`flex items-center text-white py-2 px-4 rounded shadow-lg ${
          popup.type === 'positive' ? 'bg-green-500' : 
          popup.type === 'negative' ? 'bg-red-500' : 
          'bg-blue-500'}`}
        >
          {/* Icons and message */}
          {popup.type === 'positive' && <FaCheckCircle className="mr-2" size={22} />}
          {popup.type === 'negative' && <FaExclamationCircle className="mr-2" size={22} />}
          {popup.type === 'info' && <FaInfoCircle className="mr-2" size={22} />}
          <p className="flex-grow">{popup.message}</p>
          <button onClick={hidePopup} className="ml-4 text-xl">&times;</button>
        </div>
      </div>
    </PopupContext.Provider>
  );
};