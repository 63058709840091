import React, { createContext, useContext, useState, useEffect } from 'react';
import apiFetch from '../utils/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchUserDetails = async () => {
        try {
            const data = await apiFetch('/api/userinfo');  // Adjust the endpoint as necessary
            setUser(data.user);
            setAuthenticated(true);
        } catch (error) {
            console.error('Failed to fetch user details', error);
            localStorage.removeItem('token');
            localStorage.removeItem('expires_at');
            setAuthenticated(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const expiresAt = localStorage.getItem('expires_at');
        const currentTime = new Date().getTime();

        if (token && expiresAt && currentTime < parseInt(expiresAt, 10)) {
            fetchUserDetails();
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('expires_at');
            setAuthenticated(false);
            setLoading(false);
        }
    }, []);

    const login = (token) => {
        const expiresAt = new Date().getTime() + (3600 * 8000); // 8 hour from now
        localStorage.setItem('token', token);
        localStorage.setItem('expires_at', expiresAt.toString());
        fetchUserDetails();
        setAuthenticated(true);
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expires_at');
        setAuthenticated(false);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
