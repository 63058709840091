import React from 'react';

const GanttTooltip = ({ client, eta, sale, totalWeight, style }) => {

  return (
    <div
      className="gantt-order-tooltip group-hover:scale-100 z-10 h-auto transition-all ease-in-out duration-300"
      style={{ ...style }}
    >
      <div><strong>Client:</strong> {client}</div>
      <div><strong>ETA:</strong> {new Date(eta).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
      <div><strong>Total Weight:</strong> {totalWeight} Kg</div>
      <div><strong>Sale:</strong> €{sale}</div>
    </div>
  );
};

export default GanttTooltip;
