// ReportDropdown.jsx
import React, { useState, useEffect } from 'react';

const ReportDropdown = ({ reportTypes, onSearch }) => {
  const [selectedType, setSelectedType] = useState('drivermanifest');
  const [startDate, setStartDate] = useState(getToday());
  const [endDate, setEndDate] = useState(getToday()); // Set default end date to today

  useEffect(() => {
    setStartDate(getToday());
    setEndDate(getToday());
  }, []);

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setSelectedType(type);
  };

  const handleStartDateChange = (event) => {
    const date = event.target.value;
    setStartDate(date);
  };

  const handleEndDateChange = (event) => {
    const date = event.target.value;
    setEndDate(date);
  };

  const handleSearch = () => {
    onSearch({ selectedType, startDate, endDate });
  };

  function getToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <div className="report-dropdown p-2 flex items-center justify-center mx-4 w-full">
      <div className="flex items-center justify-between space-x-4">
        <label className="text-sm font-medium text-gray-700" htmlFor="reportType">
          Select Report Type:
        </label>
        <select
          id="reportType"
          className="p-2 border rounded-md"
          value={selectedType}
          onChange={handleTypeChange}
        >
          <option value="drivermanifest">Driver Manifest</option>
          <option value="routesolutions">Route Solutions</option>
          <option value="loadingmanifest">Loading Manifest</option>
          <option value="operationcosts">Operation Costs</option>
          {reportTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <label className="text-sm font-medium text-gray-700" htmlFor="startDate">
          Start Date:
        </label>
        <input
          type="date"
          id="startDate"
          className="p-2 border rounded-md"
          value={startDate}
          onChange={handleStartDateChange}
        />

        <label className="text-sm font-medium text-gray-700" htmlFor="endDate">
          End Date:
        </label>
        <input
          type="date"
          id="endDate"
          className="p-2 border rounded-md"
          value={endDate}
          onChange={handleEndDateChange}
        />
        <button
          className="px-4 py-2 bg-green-500 text-white rounded transition-all hover:bg-green-600"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default ReportDropdown;
