import React from 'react';
import { useAuth } from '../../context/AuthContext';

const GanttControlPanel = ({ currentZoomLevel, setTargetZoomLevel, zoomLevels, saveRouteNames }) => {
  const { user } = useAuth();
  return (
    <div className="gantt-control-panel flex justify-between shadow-sm mt-2 mb-2 text-s">
      {user?.user_type === 'admin' && (
      <button
        onClick={saveRouteNames}
        className="px-2 bg-gray-400 border h-auto w-auto p-0.56 bg-gray-400 hover:bg-green-600 dark:bg-gray-800 text-green-500 hover:text-white hover:rounded-md rounded transition-all duration-100 ease-linear cursor-pointer"
      >
        Save changes
      </button>
      )}
      <div className='flex justify end'>
      <span className="mr-2">Zoom:</span>
      {zoomLevels.map((zoomLevel, index) => (
        <button
          key={index}
          className={`px-2 bg-gray-400 border border-gray-400 ${currentZoomLevel === index ? 'relative flex items-center justify-start h-auto w-auto p-0.56 bg-green-600 text-white' : 'hover:bg-green-600 text-green-500 hover:text-white rounded'}`}
          onClick={() => {
            setTargetZoomLevel(index);
          }}
        >
          {zoomLevel === 60 ? '1hr' : zoomLevel === 30 ? '30min' : zoomLevel === 15 ? '15min' : '1min'}
        </button>
      ))}
      </div>
    </div>
  );
};

export default GanttControlPanel;
