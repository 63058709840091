// src/utils/auth.js
export const saveToken = (token, expiresIn = 3600) => {
    const expiresAt = new Date().getTime() + expiresIn * 1000; // Default expiration time is 1 hour
    localStorage.setItem('token', token);
    localStorage.setItem('expires_at', expiresAt.toString());
};

export const getToken = () => {
    const token = localStorage.getItem('token');
    const expiresAt = localStorage.getItem('expires_at');
    const currentTime = new Date().getTime();

    if (!token || !expiresAt || currentTime >= parseInt(expiresAt, 10)) {
        // Token is either not set, or it has expired
        localStorage.removeItem('token');
        localStorage.removeItem('expires_at');
        return null;
    }
    return token;
};

export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
};

export const isTokenExpired = () => {
    const expiresAt = localStorage.getItem('expires_at');
    const currentTime = new Date().getTime();
    return !expiresAt || currentTime >= parseInt(expiresAt, 10);
};
