import React, { useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import { Loading, Error } from '../../components';
import apiFetch from '../../utils/api';

const Import = ({ onUploadSuccess, onUploadError, fileInputRef }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState(null);

  const closeError = () => {
    setError(null);
  };

  useEffect(() => {
    const socket = socketIOClient('/');
  
    socket.on('flash_message', (data) => {
      setLoading(true);
      setLoadingMessage(data.message);

      if (data.message === '   Optimizing Routes...  ') {
        setTimeout(() => {
          setLoading(false);
          fetchSavePlan();
        }, 15 * 60 * 1000); // Adjust time as necessary
      }

      if (data.finalMessage  && data.errorMessage) {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      }
    });

    return () => socket.disconnect();
  }, []);

  const fetchSavePlan = async () => {
    try {
      await apiFetch('/api/saveplan');
    } catch (error) {
      console.error('Error during save plan:', error);
    }
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (!file) {
      setLoading(false);
      return;
    }

    if (!file.name.toLowerCase().includes('orders')) {
      setLoading(false);
      setError('File name must contain the word "orders".');
      onUploadError({ message: 'File name must contain the word "orders".' });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);

      const responseData = await apiFetch('/api/upload', {
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': undefined // Let the browser set the content type for FormData
        }
      });

      onUploadSuccess(responseData);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      onUploadError(error);
      setLoading(false);
    }

    // Clear the input after the file is processed
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      {loading && <Loading message={loadingMessage} />}
      {error && <Error error={error} onClose={closeError} />}
      <input
        type="file"
        accept=".xlsx, .xls, .csv"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </div>
  );
};

export default Import;
