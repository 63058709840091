import React, { useEffect, useState } from 'react';
import { UnassignedOrders } from '../../containers';
import { getToken } from '../../utils/auth';
import { useAuth } from '../../context/AuthContext';  // Ensure you have this hook available

const Status = ({ chartData, unplannedJobs, refreshChartData }) => {
  const [latestId, setLatestId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAuth();  // Get user details from context

  const openModal = () => {
    if (user?.user_type === 'admin') {  // Check if the user is admin
      setIsModalOpen(true);
    } else {
      console.error('Access denied: Only admins can open the modal.');
    }
  };

  const apiFetchWithAuth = async (url, options = {}) => {
    const token = getToken();
    if (!token) {
      throw new Error('No token available. User must log in.');
    }

    const headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
    };

    const response = await fetch(url, {
      ...options,
      headers: headers,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch');
    }

    return response.json();
  };

  const fetchDataID = async () => {
    try {
      const data = await apiFetchWithAuth('/api/modeldataID');
      setLatestId(data.latest_id);
    } catch (error) {
      console.error('Error fetching ID:', error);
    }
  };

  useEffect(() => {
    // Fetch ID when the component mounts
    fetchDataID();

    // Set up a timer to periodically request updates
    const idIntervalId = setInterval(fetchDataID, 30000); // 30 sec

    // Cleanup on component unmount
    return () => clearInterval(idIntervalId);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const totalOrders = chartData.reduce((sum, route) => sum + route.orders.length, 0);
  const totalRoutes = chartData.length;
  const unassignedOrders = chartData.length > 0 ? chartData[0].unassignedOrders || 0 : 0;

  return (
    <div className="bg-gray-50 p-4 rounded-md shadow-md mx-auto max-w-35 flex flex-row justify-center">
      <div className='border-r border-l border-gray-600 pb-1'></div>
      <div className="mx-4 ">
        <p>ID: {latestId}</p>
      </div>
      <div className='border-r border-l border-gray-600 pb-1'></div>
      <div className="mx-4">
        <p>Routes Planned: {totalRoutes}</p>
      </div>
      <div className='border-r border-l border-gray-600 pb-1'></div>
      <div className="mx-4">
        <p>Orders: {totalOrders}</p>
      </div>
      <div className='border-r border-l border-gray-600 pb-1'></div>
      <div className="mx-4 border-b" onClick={openModal} style={{ cursor: 'pointer' }}>
        <p className="hover:text-green-600 transition-all duration-1003">Unassigned Orders: {unassignedOrders}</p>
      </div>

      {isModalOpen && (
        <UnassignedOrders unplannedJobs={unplannedJobs} chartData={chartData} refreshChartData={refreshChartData} onClose={() => setIsModalOpen(false)} />
      )}
      <div className='border-r border-l border-gray-600 pb-1'></div>
    </div>
  );
};

export default Status;
