// Import necessary modules and components
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import stringHash from 'string-hash';
import { TbMapPinOff, TbRouteOff, TbRoute } from "react-icons/tb";
import { MdFullscreen } from "react-icons/md";

// Map component
const Map = ({ geojsonData }) => {
  // State variables
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [map, setMap] = useState(null);
  const [showRouteSelector, setShowRouteSelector] = useState(false);
  const [showAllRoutes, setShowAllRoutes] = useState(false);
  const [showAllLabels, setShowAllLabels] = useState(true);

  // Toggle Fullscreen Function
  const toggleFullscreen = () => {
    const mapContainer = document.getElementById('map');
    if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
      if (mapContainer.requestFullscreen) {
        mapContainer.requestFullscreen();
      } else if (mapContainer.msRequestFullscreen) {
        mapContainer.msRequestFullscreen();
      } else if (mapContainer.mozRequestFullScreen) {
        mapContainer.mozRequestFullScreen();
      } else if (mapContainer.webkitRequestFullscreen) {
        mapContainer.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  // Effect to initialize the map
  useEffect(() => {
    if (!map) {
      const newMap = L.map('map', { center: [53.4129, -8.2439], zoom: 7 });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors',
      }).addTo(newMap);

      setMap(newMap);
    }
  }, [map]);

  // Memoize the color palette
  const colorPalette = useMemo(() => [
    "#D32F2F", "#C2185B", "#7B1FA2", "#512DA8", "#303F9F", "#1976D2", "#0288D1",
    "#0097A7", "#00796B", "#388E3C", "#689F38", "#AFB42B", "#FBC02D", "#FFA000",
    "#F57C00", "#E64A19", "#5D4037", "#616161", "#455A64", "#C51162", "#AA00FF",
    "#6200EA", "#304FFE", "#2962FF", "#0091EA", "#00B8D4", "#00BFA5", "#00C853",
    "#64DD17", "#AEEA00", "#FFD600", "#FFAB00", "#FF6D00", "#DD2C00", "#3E2723",
    "#212121", "#263238", "#FF1744", "#F50057", "#D500F9", "#651FFF", "#3D5AFE",
    "#2979FF", "#00B0FF", "#00E5FF", "#1DE9B6", "#00E676", "#76FF03", "#C6FF00",
    "#FFEA00", "#FFC400", "#FF9100", "#FF3D00", "#4E342E", "#424242", "#37474F",
    "#D50000", "#C51162", "#AA00FF", "#6200EA", "#304FFE", "#2962FF", "#0091EA"
  ], []);

  // Function to generate a color mapping
  const generateColorMapping = (vehiclePlans) => {
    const assignedColors = new Set();
    const colorMapping = {};

    vehiclePlans.forEach((vehiclePlan) => {
      let color = colorPalette[stringHash(vehiclePlan.vehicleId) % colorPalette.length];
      while (assignedColors.has(color)) {
        color = colorPalette[Math.floor(Math.random() * colorPalette.length)];
      }
      assignedColors.add(color);
      colorMapping[vehiclePlan.vehicleId] = color;
    });

    return colorMapping;
  };

  const colorMapping = useMemo(() => {
    return geojsonData && geojsonData.vehiclePlans ? generateColorMapping(geojsonData.vehiclePlans) : {};
  }, [geojsonData, colorPalette]);

  const getDarkerColor = useCallback((color) => {
    const darkerLightness = 40; // You can adjust this value
    const darkerSaturation = 60; // You can adjust this value
    return `hsl(${extractHue(color)},${darkerSaturation}%,${darkerLightness}%)`;
  }, []);

  const addStopCircles = useCallback((map, coordinates, color, label, sequence, stops) => {
    if (coordinates && coordinates.length === 2 && stops.length > 0) {
      const darkerColor = getDarkerColor(color);
      const fixedRadius = 20;
      const circle = L.circle([coordinates[1], coordinates[0]], {
        color: darkerColor,
        fillColor: color,
        fillOpacity: 0.8,
        radius: fixedRadius,
        dashArray: '3',
      }).addTo(map);

      // Create L.circleMarker for click event with a larger radius (invisible)
      const invisibleCircleMarker = L.circleMarker([coordinates[1], coordinates[0]], {     
        opacity: 0,  // Make the circleMarker invisible
        radius: fixedRadius + 2,  // Adjust the radius for a larger clickable area
        fillColor: 'transparent',  // Set fillColor to transparent
        color: 'transparent',
      }).addTo(map);

      if (showAllLabels) {
        // Determine the popup label based on the stop order
        const tooltipLabel = sequence !== null ? `${sequence}` : label;

        circle.bindTooltip(tooltipLabel, { permanent: true, direction: 'right' }).openTooltip();

        // Open the popup on click
        invisibleCircleMarker.on('click', function () {
          const clientName = stops[0].stopProperties.Client;
          const orderNumber = stops[0].stopId.replace('Pickup_', '').replace('Dropoff_', '');
          const sale = stops[0].stopProperties.GrossSale;
          const etatotal = stops[0].timeEstimates.arrival;
          const eta = etatotal.substring(11, 16);

          // Construct popup content
          const popupContent = `
            <strong>Client:</strong> ${clientName}<br/>
            <strong>Order:</strong> ${orderNumber}<br/>
            <strong>Sale:</strong> €${sale}<br/>
            <strong>ETA:</strong> ${eta}`;

          // Bind popup content without opening it by default
          invisibleCircleMarker.bindPopup(popupContent, { autoClose: false });

          // Open the popup
          this.openPopup();
        });
      }
    }
  }, [getDarkerColor, showAllLabels]);

  useEffect(() => {
    if (map && geojsonData && geojsonData.vehiclePlans) {
      map.eachLayer((layer) => {
        if (!(layer instanceof L.TileLayer) && !(layer instanceof L.Marker)) {
          map.removeLayer(layer);
        }
      });

      geojsonData.vehiclePlans.forEach((vehiclePlan, index) => {
        const linestringCoordinates = convertToGeoJson(vehiclePlan);

        if (linestringCoordinates && (showAllRoutes || selectedRoutes.includes(index))) {
          const routeColor = colorMapping[vehiclePlan.vehicleId];
          
          L.polyline(linestringCoordinates, { color: routeColor, weight: 5 }).addTo(map);

          vehiclePlan.plannedStops
            .filter(stop => stop.stopId.includes('Dropoff_'))
            .forEach((stop, stopIndex) => {
              addStopCircles(
                map,
                stop.geomToNextStop.coordinates[0],
                routeColor,
                stop.stopId,
                stopIndex + 1, // Pass the sequence number (1-based) to addStopCircles
                [stop] // Pass the stops array
              );
            });
        }
      });

      // Open popups for all circles
      map.eachLayer((layer) => {
        if (layer instanceof L.CircleMarker) {
          layer.openPopup();
        }
      });
    }
  }, [map, selectedRoutes, showAllRoutes, showAllLabels, geojsonData, addStopCircles, colorMapping]);

  const convertToGeoJson = (vehiclePlan) => {
    const plannedStops = vehiclePlan.plannedStops || [];
    if (plannedStops.length === 0) {
      return null;
    }

    let allCoordinates = [];
    plannedStops.forEach((stop) => {
      if (stop.geomToNextStop && stop.geomToNextStop.type === 'LineString') {
        const coordinates = stop.geomToNextStop.coordinates.map((coord) => [coord[1], coord[0]]);
        allCoordinates = allCoordinates.concat(coordinates);
      }
    });

    return allCoordinates.length > 0 ? allCoordinates : null;
  };

  // Function to extract hue from an HSL color string
  const extractHue = (color) => {
    const match = color.match(/hsl\((\d+),.*\)/);
    return match ? match[1] : 0;
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedRoutes = [...selectedRoutes];
    if (updatedRoutes.includes(index)) {
      updatedRoutes.splice(updatedRoutes.indexOf(index), 1);
    } else {
      updatedRoutes.push(index);
    }
    setSelectedRoutes(updatedRoutes);
  };

  // Function to toggle visibility of all routes
  const toggleAllRoutes = () => {
    setShowAllRoutes((prevShowAllRoutes) => !prevShowAllRoutes);
  };

  // Function to toggle visibility of all labels
  const toggleAllLabels = () => {
    setShowAllLabels((prevShowAllLabels) => !prevShowAllLabels);
  };

  // Return the JSX content
  return (
    <div>
      <div id="map" className="h-96 border-2 border-gray-300 rounded-md relative z-0">
      <button onClick={toggleFullscreen} style={{
        position: 'absolute', 
        zIndex: 1000, 
        top: '10px', 
        right: '10px', 
        backgroundColor: 'white', // Set the background color to white
        cursor: 'pointer', // Change cursor to pointer on hover
        padding: '6px', // Add some padding around the icon for better clickability
        borderRadius: '4px', // Optional: rounded corners
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)' // Optional: some shadow for better visibility
      }}>
        <MdFullscreen style={{ fontSize: '24px' }} />
      </button>
      </div>

      {/* Button container */}
      <div className="flex justify-start mt-2 mb-2">
        {/* Select Routes button */}
        <button
          className={`toggle-button relative flex items-center justify-start h-auto w-auto p-0.5 ml-2 bg-gray-400 hover:bg-green-600 dark:bg-gray-800 text-green-500 hover:text-white hover:rounded-xl rounded transition-all duration-300 ease-linear cursor-pointer shadow-lg ${showRouteSelector ? 'show' : 'hide'}`}
          onClick={() => setShowRouteSelector(!showRouteSelector)}
          style={{ zIndex: 1 }}
        >
          <TbRoute size={20} />
          <span className="ml-2">Select Routes</span>
        </button>

        {/* Show/Hide All Routes button */}
        <button
          className={`toggle-button relative flex items-center justify-start h-auto w-auto p-0.5 ml-2 bg-gray-400 hover:bg-green-600 dark:bg-gray-800 text-green-500 hover:text-white hover:rounded-xl rounded transition-all duration-300 ease-linear cursor-pointer shadow-lg ${showRouteSelector ? 'show' : 'hide'}`}
          onClick={toggleAllRoutes}
          style={{ zIndex: 1 }}
        >
          <TbRouteOff size={20} />
          <span className="ml-2">Show/Hide all Routes</span>
        </button>

        {/* Show/Hide All Routes button */}
        <button
          className={`toggle-button relative flex items-center justify-start h-auto w-auto p-0.5 ml-2 bg-gray-400 hover:bg-green-600 dark:bg-gray-800 text-green-500 hover:text-white hover:rounded-xl rounded transition-all duration-300 ease-linear cursor-pointer shadow-lg ${showRouteSelector ? 'show' : 'hide'}`}
          onClick={toggleAllLabels}
          style={{ zIndex: 1 }}
        >
          <TbMapPinOff size={20} />
          <span className="ml-2">Hide All Labels</span>
        </button>
      </div>
      
      {/* Route list container */}
      <div className={`route-list-container flex flex-wrap overflow-x-auto ${showRouteSelector ? 'visible' : 'hidden'}`}>
        {/* Map through vehicle plans and render checkboxes */}
        {geojsonData &&
          geojsonData.vehiclePlans &&
          geojsonData.vehiclePlans.map((vehiclePlan, index) => (
            <div key={index} className="route-checkbox flex items-center mb-2 mr-4" style={{ cursor: 'pointer' }}>
              {/* Checkbox input */}
              <input
                type="checkbox"
                id={`route-${index}`}
                checked={selectedRoutes.includes(index)}
                onChange={() => handleCheckboxChange(index)}
                className="peer relative h-3 w-3 shrink-0 appearance-none rounded-sm border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIi4KPCEtLSBHZW5lcmFsIEljb25zIC0tPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iWE1MSHR0cERhdGUiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDMwIDMwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4uKPC9zdmc+Cg==')] after:bg-[length:30px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-green-500 hover:ring hover:ring-gray-300 focus:outline-none"
              />

              {/* Checkbox label */}
              <label htmlFor={`route-${index}`} style={{ cursor: 'pointer' }} className="ml-2 text-sm break-words">
                {vehiclePlan.vehicleId}
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};

// Export the Map component
export default Map;
