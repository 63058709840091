// src/utils/apiFetch.js
import { getToken, removeToken } from './auth';

const apiFetch = async (url, options = {}) => {
    const token = getToken();
    if (!token) {
        throw new Error('No token available. User must log in.');
    }

    const headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}`,
    };

    // If uploading files, don't set the Content-Type header
    if (options.body instanceof FormData) {
        delete headers['Content-Type'];
    }

    const response = await fetch(url, {
        ...options,
        headers: headers,
    });

    if (!response.ok) {
        if (response.status === 401) {
            removeToken(); // Token is invalid or expired
            throw new Error('Token expired or unauthorized. Please log in again.');
        }
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch');
    }

    return response.json();
};

export default apiFetch;

