import React from 'react';
import { getToken } from '../../utils/auth';  // Adjust the path to where your auth utility is located

function SolutionsList({ files }) {
  const token = getToken();

  if (!files.length) {
    return <p>No solutions files found.</p>;
  }

  const handleDownload = (file) => {
    fetch(`/api/download/${file.path}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.path);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(error => console.error('Download failed', error));
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <ul className="list-none pl-5">
        {files.map((file, index) => (
          <li key={index} className="my-4">
            <button 
              onClick={() => handleDownload(file)}
              className="px-4 py-1 bg-gray-300 text-black rounded transition-all duration-300 hover:bg-green-600 mb-2 hover:text-white"
            >
              {file.path}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SolutionsList;
