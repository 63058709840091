import React from 'react';
import CountdownTimer from './CountdownTimer';

const Loading = ({ message }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="bg-white p-8 rounded-md w-64 flex flex-col items-center">
        <div className="relative w-12 h-12 mb-4">
          <div className="w-full h-full rounded-full absolute border-4 border-solid border-gray-200"></div>
          <div className="w-full h-full rounded-full animate-spin absolute border-4 border-solid border-green-500 border-t-transparent"></div>
        </div>
        <div className="loading-message text-gray-800">{message}</div>
        {message === '   Optimizing Routes...  ' && <CountdownTimer />}
      </div>
    </div>
  );
};

export default Loading;
