import React from 'react';
import { BiError } from 'react-icons/bi';

const Error = ({ error, onClose }) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClose();
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50"
      tabIndex="0" // Add tabIndex to make the div focusable
      onKeyDown={handleKeyPress} // Handle key press events
    >
      <div className="bg-white p-8 rounded-md w-64 flex flex-col items-center">
        <div className="flex items-center justify-center w-16 h-16 mb-4 text-red-500">
          <BiError size={80} />
        </div>
        <div className="loading-message text-gray-800">{error}</div>
        <button
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md cursor-pointer"
          onClick={onClose}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default Error;
