import React, { useState, useEffect } from 'react';
import apiFetch from '../../utils/api';

function RptsList({ onSelectFolder, selectedFolder, selectedType, startDate, endDate }) {
  const [folders, setFolders] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchFolders = async () => {
      setLoading(true); // Start loading
      setError(''); // Reset error state
      try {
        // Build query parameters and make the API call
        const queryParams = new URLSearchParams({ start_date: startDate, end_date: endDate });
        const url = selectedType === 'loadingmanifest'
          ? `/api/loading_manifest?${queryParams}`
          : `/api/drivers_manifest?${queryParams}`;
        const data = await apiFetch(url); // Use apiFetch to handle errors centrally

        if (data.folders && data.folders.length > 0) {
          setFolders(data.folders);
        } else {
          setFolders([]);
          setError('No data available');
        }
      } catch (error) {
        console.error('Failed to fetch folders:', error);
        setError('Failed to load folders. Please try again.'); // Set user-friendly error message
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchFolders();
  }, [startDate, endDate, selectedType]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-center">
        <div className="relative w-12 h-12 mb-4">
          <div className="w-full h-full rounded-full absolute border-4 border-solid border-gray-200"></div>
          <div className={`w-full h-full rounded-full absolute border-4 border-solid ${error ? 'border-red-500' : 'border-green-500'} border-t-transparent ${error ? '' : 'animate-spin'}`}></div>
        </div>
        <div className="loading-message text-gray-800 ml-2">{error ? error : 'Loading...'}</div>
      </div>
    );
  }

  return (
    <div>
      {error && !folders.length && <p className="text-gray-800 flex justify items-center h-center">{error}</p>}
      {folders.map(folder => (
        <div
          key={folder}
          onClick={() => onSelectFolder(folder)}
          className={`cursor-pointer p-1 my-1 rounded-lg ${selectedFolder === folder ? 'bg-green-500 text-white' : 'bg-white text-black'} hover:bg-gray-100 hover:text-black transition duration-150 ease-in-out`}
        >
          {folder}
        </div>
      ))}
    </div>
  );
}

export default RptsList;
