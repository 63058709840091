import React, { useState, useEffect } from 'react';
import { Map, GanttChart, Navbar, StatusH, DataParse } from '../components';
import { Topbar } from '../containers';
import apiFetch from '../utils/api';

const Main = () => {
  const [geojsonData, setGeojsonData] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [availableFiles, setAvailableFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');

  // useEffect for fetching available files when the component mounts
  useEffect(() => {
    const fetchAvailableFiles = async () => {
      try {
        const files = await apiFetch('/api/availableFiles');
        setAvailableFiles(files);
        if (files.length > 0) {
          setSelectedFile(files[0]); // Select the first file by default
        }
      } catch (error) {
        console.error('Error fetching available files:', error);
      }
    };

    fetchAvailableFiles();
  }, []); // Empty dependency array ensures this runs only once

  // useEffect for fetching data when selectedFile changes
  useEffect(() => {
    const fetchDataForSelectedFile = async () => {
      if (selectedFile) {
        try {
          const data = await apiFetch(`/api/solutions/${selectedFile}`);
          const parsedData = DataParse(data); // Assuming DataParse is a function you have defined
          setChartData(parsedData);
          setGeojsonData(data);
        } catch (error) {
          console.error(`Error fetching data from ${selectedFile}:`, error);
        }
      }
    };

    fetchDataForSelectedFile();
  }, [selectedFile]); // Dependency array includes selectedFile to trigger when it changes

  const handleFileSelection = (file) => {
    setSelectedFile(file);
  };

  return (
    <div>
      <Navbar />
      <Topbar />
      <div className='ml-16'>
        <div className="grid grid-cols-1 gap-4 ">
          <div className="bg-white mt-20">
            <div className='flex justify-end text-red-700 text-lg'>--Historical Routes⚠️--</div>
            <StatusH chartData={chartData} availableFiles={availableFiles} setSelectedFile={handleFileSelection} />
          </div>
          <div className="bg-white p-2 rounded-md shadow-md">
            <Map geojsonData={geojsonData} />
          </div>
          <div className="bg-white p-4 rounded-md shadow-md">
            <GanttChart chartData={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
