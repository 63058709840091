import React, { useState, useEffect } from 'react';
import apiFetch from '../../utils/api';

function FolderContents({ selectedFolder }) {
  const [files, setFiles] = useState([]);

  const getToken = () => {
    return localStorage.getItem('token'); // Adjust based on where you're storing the token
  };

  const handlePrintAllManifests = () => {
    if (!selectedFolder) {
      alert('No folder selected');
      return;
    }
    const token = getToken();
    const url = `/api/display_pdf/${selectedFolder}/All_Routes.pdf?token=${token}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    const fetchFolderContents = async () => {
      if (!selectedFolder) return;

      try {
        const response = await apiFetch(`/api/folder_contents/${selectedFolder}`, {
          headers: {
            'Authorization': `Bearer ${getToken()}`
          }
        });
        console.log('API Response:', response); // Debugging
        if (response.files) {
          setFiles([...response.files].reverse());
        }
      } catch (error) {
        console.error('Error fetching folder contents:', error);
        alert('Failed to fetch folder contents');
      }
    };

    fetchFolderContents();
  }, [selectedFolder]);

  if (!selectedFolder) return null;

  return (
    <div>
      <div className='flex justify-center'>
        <button className="px-4 py-2 bg-green-500 text-white rounded transition-all hover:bg-green-600 mb-2" onClick={handlePrintAllManifests}>
          Print All Manifests
        </button>
      </div>
      <div className="flex flex-wrap -mx-2 border-t-4 border-slate-200">
        {files.map((file, index) => (
          <div key={index} className="w-1/3 px-2 mb-2 mt-1">
            <a href={`/api/display_pdf/${selectedFolder}/${file.path}?token=${getToken()}`} target="_blank" rel="noopener noreferrer" className="block p-4 bg-white shadow rounded hover:bg-gray-100">
              {file.path}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FolderContents;
