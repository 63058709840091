import React, { useEffect, useState } from 'react';

const CurrentTimeLine = ({ startHour, operationalHours, currentZoomLevel, zoomLevels, totalWidth }) => {
  const [currentTimeOffset, setCurrentTimeOffset] = useState(0);

  const calculateCurrentTimeOffset = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const totalMinutesFromStart = (hours * 60 + minutes) - (startHour * 60);
    const interval = zoomLevels[currentZoomLevel];
    const totalOperationalMinutes = operationalHours * interval;

    // Adjust the offset to start exactly at the start of the operational hours
    const offset = (totalMinutesFromStart / totalOperationalMinutes) * totalWidth;

    return Math.max(0, offset); // Ensure offset is not negative
  };

  useEffect(() => {
    const updateOffset = () => {
      const offset = calculateCurrentTimeOffset();
      setCurrentTimeOffset(offset);
    };

    updateOffset();
    const intervalId = setInterval(updateOffset, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [currentZoomLevel, startHour, zoomLevels, operationalHours, totalWidth]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: `${currentTimeOffset}px`,
        width: '2px',
        height: '100%',
        backgroundColor: 'red',
        zIndex: 10,
      }}
    />
  );
};

export default CurrentTimeLine;
