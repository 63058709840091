import React, { useState, useEffect } from 'react';
import { Navbar, ReportDropdown } from '../components';
import { Topbar, RptsList, DriverManifest, SolutionsList, LoadingManifest } from '../containers';
import apiFetch from '../utils/api';

const Reports = () => {
  const [reportTypes, setReportTypes] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [startDate, setStartDate] = useState(getToday());
  const [endDate, setEndDate] = useState(getToday());
  const [selectedType, setSelectedType] = useState('');
  const [solutionsFiles, setSolutionsFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    apiFetch('/api/reportTypes')
      .then((data) => setReportTypes(data))
      .catch((error) => {
        console.error('Error fetching report types:', error);
        setError('Failed to load report types.');
      });
  }, []);

  const handleSearch = async ({ selectedType, startDate, endDate }) => {
    console.log(selectedType, startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedType(selectedType);
    let url;
    setError(null);

    switch (selectedType) {
      case 'drivermanifest':
        url = `/api/drivers_manifest?start_date=${startDate}&end_date=${endDate}`;
        break;
      case 'routesolutions':
        url = `/api/solutions_files?start_date=${startDate}&end_date=${endDate}`;
        break;
      case 'loadingmanifest':
        url = `/api/loading_manifest?start_date=${startDate}&end_date=${endDate}`;
        break;
      default:
        console.error("Unknown report type:", selectedType);
        setError('Unknown report type.');
        return;
    }

    console.log("Generated URL:", url);

    try {
      const data = await apiFetch(url);
      console.log("API response data:", data);

      if (selectedType === 'drivermanifest' || selectedType === 'loadingmanifest') {
        setFolders(data.folders || []);
        setSelectedFolder(null); // Reset folder selection
      } else if (selectedType === 'routesolutions') {
        setSolutionsFiles(data.files || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data.');
    }
  };

  function getToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <Navbar />
      <Topbar />
      <div className='ml-16'>
        <div className="container mx-auto mt-16 p-4">
          <h1 className="text-2xl font-bold mb-4">Reports</h1>
          <div className="grid grid-cols-1 gap-4">
            <div className="bg-white p-4 rounded-md shadow-md">
              <ReportDropdown
                reportTypes={reportTypes}
                onSearch={handleSearch}
              />
            </div>
            {(selectedType === 'drivermanifest' || selectedType === 'loadingmanifest') && (
              <RptsList onSelectFolder={setSelectedFolder} selectedFolder={selectedFolder} selectedType={selectedType} startDate={startDate} endDate={endDate} />
            )}
            {selectedType === 'routesolutions' && (
              <SolutionsList files={solutionsFiles} />
            )}
            {selectedType === 'drivermanifest' && selectedFolder && (
              <div className="bg-white p-4 rounded-md shadow-lg">
                <DriverManifest selectedFolder={selectedFolder} />
              </div>
            )}
            {selectedType === 'loadingmanifest' && selectedFolder && (
              <div className="bg-white p-4 rounded-md shadow-lg">
                <LoadingManifest selectedFolder={selectedFolder} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
