const DataParse = (data) => {
  const parsedData = data.vehiclePlans.map((plan) => {
    let leaveDepotTime = null;
    let previousStopCompleteTime = null;

    // Extract dispatchedIncompleteStartPoint time string
    const dispatchedIncompleteStartPointTime = plan.dispatchedIncompleteStartPoint?.time || null;

    const route = {
      routeName: plan.vehicleId,
      status: 'in operation', // This might not be necessary depending on your use case
      orders: [],
      breaks: [], // Add a breaks array to store break information
      totalValue: 0,
      totalWeight: 0,
      totalKm: Math.round(plan.planEndPoint?.cumulativeTravelKm || 0),
      totalHours: parseFloat((((plan.timeStatistics?.waitTimeSeconds || 0) +
                   (plan.timeStatistics?.operationTimeSeconds || 0) +
                   (plan.timeStatistics?.travelSeconds || 0)) / 3600).toFixed(1)),
      totalOrders: 0,
      planStart: plan.planStartPoint?.time,
      planEnd: plan.planEndPoint?.time,
      leaveDepotTime: null,
      dispatchedIncompleteStartPointTime: dispatchedIncompleteStartPointTime // Add the extracted time here
    };

    plan.plannedStops.forEach((stop) => {
      const isDropoff = stop.stopId.startsWith("Dropoff");
      const isPickup = stop.stopId.startsWith("Pickup");
      const isBreak = stop.stopId === "30min Break"; // Check if the stop is a break

      // Calculate the leave depot time as the complete time of the stop immediately before the first "Dropoff_" stop
      if (isDropoff && leaveDepotTime === null && previousStopCompleteTime !== null) {
        leaveDepotTime = previousStopCompleteTime;
      }

      if (isDropoff) {
        const order = {
          id: stop.stopId,
          orderName: stop.stopId.slice(8), // Assuming 'Dropoff_' prefix is to be removed
          status: 'in operation', // Adjust based on your JSON structure if needed
          totalValue: Math.round(parseFloat(stop.stopProperties?.GrossSale || 0)),
          totalWeight: Math.round(parseFloat(stop.stopProperties?.Weight || 0)),
          ETA: stop.timeEstimates?.arrival || "",
          completion: stop.timeEstimates?.complete || "", // Assuming this is how completion time is provided
          Client: stop.stopProperties?.Client || "",
          Sale: stop.stopProperties?.GrossSale || 0,
        };

        route.orders.push(order);
        route.totalOrders++;
      }

      if (isBreak) {
        const breakStop = {
          id: stop.stopId,
          breakName: "30min Break",
          startTime: stop.timeEstimates?.arrival || "",
          endTime: stop.timeEstimates?.complete || "", // Assuming this is how completion time is provided
        };

        route.breaks.push(breakStop);
      }

      // Update previous stop complete time
      previousStopCompleteTime = stop.timeEstimates?.complete || previousStopCompleteTime;
    });

    // Ensure leaveDepotTime is set
    if (!leaveDepotTime && previousStopCompleteTime) {
      leaveDepotTime = previousStopCompleteTime;
    }

    // Set the leaveDepotTime to the calculated leave depot time
    route.leaveDepotTime = leaveDepotTime;

    // Sum up total value and weight for the route
    route.totalValue = route.orders.reduce((total, order) => total + order.totalValue, 0);
    route.totalWeight = route.orders.reduce((total, order) => total + order.totalWeight, 0);

    // Parse dispatchedIncompleteStartPointTime like other time strings
    if (route.dispatchedIncompleteStartPointTime) {
      route.dispatchedIncompleteStartPointTime = new Date(route.dispatchedIncompleteStartPointTime).toISOString();
    }

    return route;
  });

  // Handling unassigned orders if part of your requirement
  const unplannedAnalysis = data.unplannedAnalysis || [];
  const unassignedOrders = unplannedAnalysis.length;

  if (parsedData.length > 0) {
    parsedData[0].unassignedOrders = unassignedOrders;
  }

  return parsedData;
};

export default DataParse;
