import React, { useCallback, useState, useMemo, useRef } from 'react';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';
import GanttTooltip from './ganttTooltip';
import CurrentTimeLine from './ganttCurrentTimeline';
import { useAuth } from '../../context/AuthContext';
import apiFetch from '../../utils/api';
import { usePopup } from '../../components/loading/Popup';

const GanttChartContent = ({ chartData, operationalHours, currentZoomLevel, ordersStatus, startHour, zoomLevels, setChartData, refreshChartData }) => {
  const MIN_LABEL_SPACING = 50; // Minimum pixel spacing between labels
  const [tooltipData, setTooltipData] = useState(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const orderRefs = useRef({});
  const { user } = useAuth();
  const { showPopup } = usePopup();
  
  const totalWidth = useMemo(() => operationalHours * MIN_LABEL_SPACING, [operationalHours, MIN_LABEL_SPACING]);

  const getStatusColor = useCallback((order) => {
    const statusInfo = ordersStatus[order.id];
    if (statusInfo) {
      const deliveryTime = new Date(statusInfo.lastUpdate);
      const eta = new Date(order.completion || order.ETA);
      const minutesLate = (deliveryTime - eta) / (60 * 1000); // Calculate the delay in minutes
  
      if (statusInfo.isCompleted) {
        if (minutesLate <= 30 || deliveryTime <= eta) { // Delivered within 30 minutes after the ETA or any time before the ETA
          return 'bg-green-500'; // On time or earlier
        } else {
          return 'bg-orange-400'; // More than 30 minutes late
        }
      }
    }
    return 'bg-gray-300'; // Default color for orders not yet completed or without valid status info
  }, [ordersStatus]);

  const getLeftOffset = useCallback((dateTime) => {
    const date = new Date(dateTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const totalMinutesFromStart = (hours * 60) + minutes - (startHour * 60);
    const interval = zoomLevels[currentZoomLevel];
    const totalOperationalMinutes = operationalHours * interval;
    const offset = (totalMinutesFromStart / totalOperationalMinutes) * totalWidth;
    return offset;
  }, [currentZoomLevel, startHour, zoomLevels, operationalHours, totalWidth]);

  const getOrderWidth = useCallback((start, end) => {
    const startDateTime = new Date(start);
    const endDateTime = new Date(end);
    const durationMinutes = (endDateTime - startDateTime) / (1000 * 60);
    const interval = zoomLevels[currentZoomLevel];
    const totalOperationalMinutes = operationalHours * interval;
    const width = (durationMinutes / totalOperationalMinutes) * totalWidth;
    return width;
  }, [currentZoomLevel, zoomLevels, operationalHours, totalWidth]);

  const handleMouseEnter = useCallback((event, order) => {
    const orderElement = orderRefs.current[order.id];
    if (orderElement) {
      const rect = orderElement.getBoundingClientRect();
      const tooltip = {
        client: order.Client,
        eta: order.ETA,
        sale: Number(order.Sale).toFixed(2), // Ensure sale is a number and format to 2 decimal places
        totalWeight: order.totalWeight,
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        width: rect.width,
        height: rect.height,
      };
      setTooltipData(tooltip);
      setIsTooltipVisible(true);
    }
  }, []);

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
    setTooltipData(null);
  };

  // Helper function to format time labels
  const formatTimeLabel = (minutes) => {
    const date = new Date();
    date.setHours(0, minutes, 0, 0);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
  };

  const handleDeleteRoute = async (route) => {
    // Show a confirmation alert
    const isConfirmed = window.confirm(`Are you sure you want to delete the route: ${route.routeName}?`);
    
    if (isConfirmed) {
      try {
        const data = await apiFetch('/api/removevehicle', {
          method: 'POST',
          body: JSON.stringify({ routeName: route.routeName }),
        });
  
        if (data.message) { // Assuming 'message' key indicates success
          showPopup(data.message, 'positive'); // Show success message
          setTimeout(() => {
            refreshChartData(); // Refresh the chart data after a delay
          }, 1000); // Adjust the delay as necessary
        } else {
          showPopup(data.error || 'Failed to delete the route', 'error'); // Show error message
          console.error('Failed to delete the route:', data.error);
        }
      } catch (error) {
        showPopup('Error: ' + error.message, 'error'); // Show error message
        console.error('Error:', error);
      }
    }
  };

  return (
    <div className="flex w-full mt-2">
      <div
        className="bg-white p-4 shadow-md"
        style={{ minWidth: '320px', position: 'sticky', left: 0, zIndex: 10 }}
      >
        {chartData.map((route, index) => (
          <div
            key={index}
            className="text-sm text-green-600 font-bold border-b-2 py-2 relative group"
            style={{ height: '4rem' }}
          >
            <span className="truncate max-w-full block">
              {route.routeName}
            </span>
            {user?.user_type === 'admin' && (
              <div className="absolute right-0 top-1/2 transform -translate-y-1/2 flex space-x-2 opacity-0 group-hover:opacity-100 cursor-pointer">
                <FaTimes 
                  className="text-red-400 : hover:text-red-600" 
                  onClick={() => handleDeleteRoute(route)}
                />
              </div>
            )}
            <div className="flex text-xs font-medium text-gray-800">
              <div className="mx-1">Jobs: {route.totalOrders}</div>
              <div className="mx-1">Value: €{Number(route.totalValue).toFixed(2)}</div>
            </div>
            <div className="flex text-xs font-medium text-gray-800">
              <div className="mx-1">{route.totalWeight}Kg</div>
              <div className="mx-1">{route.totalKm}Km</div>
              <div className="mx-1">{route.totalHours}Hrs</div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex-grow relative overflow-auto">
        <div
          className="flex justify-between border-b"
          style={{
            width: `${totalWidth}px`,
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 10,
          }}
        >
          {Array.from({ length: operationalHours }).map((_, index) => {
            const minutes = startHour * 60 + index * zoomLevels[currentZoomLevel];
            const timeLabel = formatTimeLabel(minutes);
            return (
              <span key={index} className="text-center text-sm text-gray-600 transform-origin-bottom-left">
                {timeLabel}
              </span>
            );
          })}
        </div>

        {chartData.map((route, routeIndex) => {
          const planStart = new Date(route.dispatchedIncompleteStartPointTime);
          const leaveDepotTime = new Date(route.leaveDepotTime);
          const firstOrderETA = route.orders.length > 0 ? new Date(route.orders[0].ETA) : null;
          const startToLeaveDepotWidth = getOrderWidth(planStart, leaveDepotTime);
          const leaveDepotToFirstOrderWidth = firstOrderETA ? getOrderWidth(leaveDepotTime, firstOrderETA) : 0;

          return (
            <div key={route.routeName} className="relative" style={{ height: '4rem' }}>
              {/* Start Segment */}
              {route.orders.length > 0 && (
                <div
                  className="absolute flex"
                  style={{
                    left: `${getLeftOffset(route.dispatchedIncompleteStartPointTime)}px`,
                    width: `${startToLeaveDepotWidth + leaveDepotToFirstOrderWidth}px`,
                    top: '0',
                    height: '4rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    zIndex: 0,
                  }}
                >
                  <div
                    className="text-xs p-1 flex items-center"
                    style={{
                      width: `${startToLeaveDepotWidth}px`,
                      backgroundColor: '#ebedef',
                      borderRight: '2px solid',
                      borderRadius: '3px 0 0 3px',
                      backgroundImage: 'repeating-linear-gradient(135deg, rgb(91, 255, 151) 0, rgb(134, 239, 173) 4px, transparent 8px, transparent 18px)',
                    }}
                  >
                    <span className="mx-1 text-black font-bold">
                      Start: {planStart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </span>
                  </div>
                  <div
                    className="text-xs p-1 flex items-center"
                    style={{
                      width: `${leaveDepotToFirstOrderWidth}px`,
                      backgroundColor: '#ebedef',
                      borderRight: '2px solid blue',
                      borderRadius: '3px 0 0 3px',
                      backgroundImage: 'repeating-linear-gradient(135deg, rgb(255, 255, 0) 0, rgb(255, 255, 100) 4px, transparent 8px, transparent 18px)',
                    }}
                  >
                    <span className="mx-1 text-black font-bold">
                      Leave Depot: {leaveDepotTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </span>
                  </div>
                </div>
              )}

              {/* Orders */}
              {route.orders.map((order, orderIndex) => (
                <div
                  key={order.id}
                  ref={(el) => (orderRefs.current[order.id] = el)}
                  className={`${getStatusColor(order)} text-xs p-1 absolute`}
                  style={{
                    left: `${getLeftOffset(order.ETA)}px`,
                    width: `${
                      orderIndex === route.orders.length - 1
                        ? getOrderWidth(order.ETA, order.completion)
                        : getOrderWidth(order.ETA, route.orders[orderIndex + 1].ETA)
                    }px`,
                    top: '0',
                    height: '4rem',
                    border: '1px solid black',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  onMouseEnter={(e) => handleMouseEnter(e, order)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div>
                    <div className="font-semibold text-m">{order.orderName}</div>
                    <div>{order.Client}</div>
                    <div>{new Date(order.ETA).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} | {order.totalWeight}Kg | €{Number(order.Sale).toFixed(2)} </div>
                  </div>
                </div>
              ))}

              {/* Breaks */}
              {route.breaks.map((breakStop) => (
                <div
                  key={breakStop.id}
                  className="bg-gray-300 text-xs p-1 absolute flex items-center border-2"
                  style={{
                    left: `${getLeftOffset(breakStop.startTime)}px`,
                    width: `${getOrderWidth(breakStop.startTime, breakStop.endTime)}px`,
                    top: '0',
                    height: '4rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    borderRadius: '3px',
                    backgroundImage: 'repeating-linear-gradient(135deg, rgb(65, 105, 225) 0, rgb(65, 105, 225) 4px, transparent 8px, transparent 18px)',
                  }}
                >
                  <span className="mx-1 text-black font-bold">
                    {breakStop.breakName}: {new Date(breakStop.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </span>
                </div>
              ))}

              {/* End Segment */}
              {route.orders.length > 0 && (
                <div
                  className="bg-gray-300 text-xs p-1 absolute flex items-center border-2"
                  style={{
                    left: `${getLeftOffset(route.orders[route.orders.length - 1].completion)}px`,
                    width: `${getOrderWidth(route.orders[route.orders.length - 1].completion, route.planEnd)}px`,
                    top: '0',
                    height: '4rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    borderRadius: '3px',
                    backgroundColor: '#ebedef',
                    backgroundImage: 'repeating-linear-gradient(45deg, rgb(91, 255, 151) 0, rgb(134, 239, 173) 4px, transparent 8px, transparent 18px)',
                    zIndex: 0,
                  }}
                >
                  <span className="mx-1 text-black font-bold">
                    End: {new Date(route.planEnd).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </span>
                </div>
              )}
            </div>
          );
        })}

        {/* Insert the CurrentTimeLine component */}
        <CurrentTimeLine
          startHour={startHour}
          operationalHours={operationalHours}
          currentZoomLevel={currentZoomLevel}
          zoomLevels={zoomLevels}
          totalWidth={totalWidth}
        />
      </div>

      {tooltipData && (
        <GanttTooltip
          client={tooltipData.client}
          eta={tooltipData.eta}
          sale={tooltipData.sale}
          totalWeight={tooltipData.totalWeight}
          style={{
            top: tooltipData.y,
            left: tooltipData.x,
            width: tooltipData.width,
            height: tooltipData.height + 12,
            transform: 'translate(-50%, -100%)', // Center horizontally and position above the order block
          }}
          isVisible={isTooltipVisible}
        />
      )}
    </div>
  );
};

export default GanttChartContent;
