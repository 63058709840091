// Main.js
import React, { useState, useEffect } from 'react';
import { Map, GanttChart, Navbar, Status, DataParse } from '../components';
import { Topbar } from '../containers';
import apiFetch from '../utils/api';
import { usePopup } from '../components/loading/Popup';

const Main = () => {
  const [geojsonData, setGeojsonData] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [unplannedJobs, setUnplannedJobs] = useState([]);
  const [errorShown, setErrorShown] = useState(false); // State to track if the error has been shown
  const { showPopup } = usePopup();

  const fetchChartData = async () => {
    try {
      const jsonData = await apiFetch('/api/modeldata');
      const vehiclePlans = DataParse(jsonData);
      setChartData(vehiclePlans);
      setGeojsonData(jsonData);
      setUnplannedJobs(jsonData.unplannedJobs || []);
      setErrorShown(false); // Reset the error shown flag
    } catch (error) {
      console.error('Error fetching data:', error);
      if (!errorShown) {
        showPopup(error.message, 'negative'); // Show popup only if not already shown
        setErrorShown(true); // Set the flag to prevent repeated popups
      }
    }
  };

  useEffect(() => {
    fetchChartData();
    const intervalId = setInterval(fetchChartData, 30000); // Fetch data every 30 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [showPopup, errorShown]); // Include showPopup and errorShown in the dependency array

  return (
    <div>
      <Navbar refreshChartData={fetchChartData} />
      <Topbar />
      <div className='ml-16'>
        <div className="grid grid-cols-1 gap-4 "> {/* Example grid layout with 2 columns */}
          <div className="bg-white p-2 mt-20">
            <Status chartData={chartData} unplannedJobs={unplannedJobs} refreshChartData={fetchChartData} />
          </div>
          <div className="bg-white p-2 rounded-md shadow-md">
            <Map geojsonData={geojsonData} />
          </div>
          <div className="bg-white p-4 rounded-md shadow-md">
            <GanttChart chartData={chartData} refreshChartData={fetchChartData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
